import { combine, sample } from "effector";
import { option as O } from "fp-ts/";
import { pipe } from "fp-ts/lib/function";
import { Option } from "fp-ts/lib/Option";
import domain from "../domain";
import { events } from "../events";
import { filter } from "../filter";
import { frmt } from "../format";
import { stores } from "../stores";
import dayjs from "dayjs";

const onTransferVested = domain.createEvent("transfer vested");
const onTransfer = domain.createEvent("transfer");
const onTransferOwnership = domain.createEvent("transfer ownership");

const onOwnerChange = domain.createEvent<string>("owner");
const onRecipientChange = domain.createEvent<string>("recipient");
const onAmountChange = domain.createEvent<string>("amount");
const onAmountChangeValid = domain.createEvent<string>("amount valid");
const onStartDateChange = domain.createEvent<Date>("startDate");
const onYearChange = domain.createEvent<string>("year");
const onMonthChange = domain.createEvent<string>("month");
const onDayChange = domain.createEvent<string>("day");
const onDurationChange = domain.createEvent<string>("duration");
const onDurationChangeValid = domain.createEvent<string>("duration valid");
const onBonusPercentChange = domain.createEvent<string>("bonusPercent");
const onBonusChange = domain.createEvent<string>("bonus");
const onBonusChangeValid = domain.createEvent<string>("bonus valid");

const onTabChange = domain.createEvent<number>("blockchain tab change");
const onUnlock = domain.createEvent("unlock");

onUnlock.watch(() => events.orderSelectedEvent(O.none));

onAmountChange
  .filter({ fn: filter.bigDecimal })
  .map(frmt.nativeNum)
  .watch(onAmountChangeValid);

onBonusChange
  .filter({ fn: filter.bigDecimal })
  .map(frmt.nativeNum)
  .watch(onBonusChangeValid);

onDurationChange
  .filter({ fn: filter.zeroOrPositiveInteger })
  .map(frmt.nativeNum)
  .watch(onDurationChangeValid);

const $bonusPercent = domain
  .createStore<string>("", {
    name: "bonusPercent",
  })
  .on(onBonusPercentChange, (_, payload) => payload)
  .reset(events.txidSetEvent);

const $year = domain
  .createStore<string>("", {
    name: "year",
  })
  .on(onYearChange, (_, payload) => payload)
  .reset(events.txidSetEvent);

const $month = domain
  .createStore<string>("", {
    name: "month",
  })
  .on(onMonthChange, (_, payload) => payload)
  .reset(events.txidSetEvent);

const $day = domain
  .createStore<string>("", {
    name: "day",
  })
  .on(onDayChange, (_, payload) => payload)
  .reset(events.txidSetEvent);

const $locked = domain
  .createStore<boolean>(false, {
    name: "locked",
  })
  .on(events.orderSelectedEvent, (_, payload) => O.isSome(payload))
  .reset(events.orderDeslectedEvent, events.txidSetEvent);

const $tabIndex = domain
  .createStore<number>(0, {
    name: "blockchain tab index",
  })
  .on(
    sample({
      source: $locked,
      clock: onTabChange,
      fn: (locked, tabIndex) => ({ locked, tabIndex }),
    }),
    (state, payload) => (payload.locked ? state : payload.tabIndex)
  )
  .on(events.orderSelectedEvent, (state, payload) =>
    pipe(
      payload,
      O.map((p) => (p.vesting_start_date ? 0 : 1)),
      O.getOrElseW(() => state)
    )
  );

const $amount = domain
  .createStore<Option<string>>(O.none, {
    name: "balance",
  })
  .on(onAmountChangeValid, (_, payload) => O.fromNullable(payload))
  .on(events.orderSelectedEvent, (_, payload) =>
    pipe(
      payload,
      O.map((p) => frmt.units(p.amount))
    )
  )
  .reset(events.orderDeslectedEvent, events.txidSetEvent);

const $bonus = domain
  .createStore<Option<string>>(O.none, {
    name: "bonus",
  })
  .on(onBonusChangeValid, (_, payload) => O.fromNullable(payload))
  .reset(events.txidSetEvent);

const $duration = domain
  .createStore<Option<string>>(O.none, {
    name: "duration",
  })
  .on(onDurationChangeValid, (_, payload) => O.fromNullable(payload))
  .reset(events.orderDeslectedEvent, events.txidSetEvent);

const $recipient = domain
  .createStore<Option<string>>(O.none, {
    name: "recipient",
  })
  .on(onRecipientChange, (_, payload) => O.fromNullable(payload))
  .on(events.orderSelectedEvent, (_, payload) =>
    pipe(
      payload,
      O.map((p) => O.fromNullable(p.receiver)),
      O.getOrElseW(() => O.none)
    )
  )
  .reset(events.orderDeslectedEvent, events.txidSetEvent);

const $owner = domain
  .createStore<Option<string>>(O.none, {
    name: "owner",
  })
  .on(onOwnerChange, (_, payload) => O.fromNullable(payload));

const $startDate = domain
  .createStore<Option<Date>>(O.none, {
    name: "date",
  })
  .on(onStartDateChange, (_, payload) => O.fromNullable(payload))
  .on(events.orderSelectedEvent, (_, payload) =>
    pipe(
      payload,
      O.map((p) => O.fromNullable(p.vesting_start_date)),
      O.getOrElseW(() => O.none)
    )
  )
  .reset(events.orderDeslectedEvent, events.txidSetEvent);

combine({
  startDate: $startDate,
  year: $year,
  month: $month,
  day: $day,
})
  .map(({ year, month, day, startDate }) => {
    const start = O.getOrElse(() => new Date())(startDate);
    const end = dayjs(start)
      .add(Number(year || "0"), "year")
      .add(Number(month || "0"), "month")
      .add(Number(day || "0"), "day")
      .toDate();
    return `${(end.getTime() - start.getTime()) / 1000}`;
  })
  .watch(onDurationChange);

combine({
  amount: $amount,
  bonusPercent: $bonusPercent,
})
  .map(({ amount, bonusPercent }) => {
    const amountNum = Number(O.getOrElse(() => "0")(amount));
    const percentNum = Number(bonusPercent || "0");
    return `${(amountNum * percentNum) / 100}`;
  })
  .watch(onBonusChange);

const $vestedTransferRequest = combine({
  contract: stores.$contract,
  amount: $amount,
  recipient: $recipient,
  startDate: $startDate,
  duration: $duration,
  bonus: $bonus,
});

const $transferRequest = combine({
  contract: stores.$contract,
  amount: $amount,
  recipient: $recipient,
});

const $transferOwnershipRequest = combine({
  contract: stores.$contract,
  newOwner: $owner,
});

sample({
  source: $vestedTransferRequest,
  clock: onTransferVested,
})
  .filter({ fn: ({ contract }) => O.isSome(contract) })
  .map(({ contract, amount, recipient, startDate, duration, bonus }) => ({
    contract: O.getOrElseW(() => undefined)(contract),
    amount: O.getOrElse(() => "0")(amount),
    recipient: O.getOrElse(() => "")(recipient),
    startDate: O.getOrElse(() => new Date())(startDate),
    duration: O.getOrElse(() => "0")(duration),
    bonus: O.getOrElse(() => "0")(bonus),
  }))
  .map((payload) => ({ ...payload, contract: payload.contract! }))
  .watch(events.startVestedTransferEvent);

sample({
  source: $transferOwnershipRequest,
  clock: onTransferOwnership,
})
  .filter({ fn: ({ contract }) => O.isSome(contract) })
  .map(({ contract, newOwner }) => ({
    contract: O.getOrElseW(() => undefined)(contract),
    newOwner: O.getOrElse(() => "")(newOwner),
  }))
  .map((payload) => ({ ...payload, contract: payload.contract! }))
  .watch(events.startOwnershipTransferEvent);

sample({
  source: $transferRequest,
  clock: onTransfer,
})
  .filter({ fn: ({ contract }) => O.isSome(contract) })
  .map(({ contract, amount, recipient }) => ({
    contract: O.getOrElseW(() => undefined)(contract),
    amount: O.getOrElse(() => "0")(amount),
    recipient: O.getOrElse(() => "")(recipient),
  }))
  .map((payload) => ({ ...payload, contract: payload.contract! }))
  .watch(events.startTransferEvent);

sample({
  source: $transferOwnershipRequest,
  clock: onTransferOwnership,
})
  .filter({ fn: ({ contract }) => O.isSome(contract) })
  .map(({ contract, newOwner }) => ({
    contract: O.getOrElseW(() => undefined)(contract),
    newOwner: O.getOrElse(() => "")(newOwner),
  }))
  .map((payload) => ({ ...payload, contract: payload.contract! }))
  .watch(events.startOwnershipTransferEvent);

export const blockchainStore = {
  $amount,
  $bonus,
  $bonusPercent,
  $duration,
  $year,
  $month,
  $day,
  $recipient,
  $startDate,
  $owner,
  $tabIndex,
  $locked,
  onBonusChange,
  onBonusPercentChange,
  onUnlock,
  onOwnerChange,
  onAmountChange,
  onDurationChange,
  onYearChange,
  onMonthChange,
  onDayChange,
  onRecipientChange,
  onStartDateChange,
  onTransferVested,
  onTransfer,
  onTransferOwnership,
  onTabChange,
  onConnect: events.connectWeb3Event,
};
