import { Badge, Box } from "@chakra-ui/react";
import { useEvent, useStore } from "effector-react";
import * as React from "react";
import { blockchainStore } from "./blockchain-store";

export const Locked: React.FC = () => {
  const locked = useStore(blockchainStore.$locked);
  const onUnlock = useEvent(blockchainStore.onUnlock);

  return locked ? (
    <Box className="lock">
      <Badge
        w="100%"
        onClick={() => onUnlock()}
        textAlign="center"
        marginBottom={4}
        color="red.400"
        sx={{
          ".lock:hover &": {
            color: "green.400",
            cursor: "pointer",
          },
        }}
      >
        locked
      </Badge>
    </Box>
  ) : null;
};
