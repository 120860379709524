import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { option as O } from "fp-ts";
import { Option } from "fp-ts/lib/Option";
import * as React from "react";
import { frmt } from "../format";

export const BonusInput: React.FC<{
  amount: Option<string>;
  percent: string;
  disabled?: boolean;
  onChange: (amount: string) => void;
  onPercentChange?: (percent: string) => void;
}> = ({ amount, disabled = false, percent, onChange, onPercentChange }) => {
  return (
    <>
      <FormControl id="amount">
        <Flex align="center" mb={2}>
          <FormLabel>Bonus</FormLabel>
          {!!onPercentChange && (
            <Flex ml={5} align="center">
              <NumberInput
                w="5em"
                min={0}
                size="sm"
                onChange={onPercentChange}
                mr={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              %
            </Flex>
          )}
        </Flex>
        <Input
          disabled={disabled}
          onChange={(e) => onChange(e.currentTarget.value)}
          value={O.getOrElseW(() => "")(amount)}
        />
        <Input
          disabled
          value={frmt.bigNumber(O.getOrElseW(() => "0")(amount))}
        />
        <Input
          disabled
          value={frmt.bigNumberHex(O.getOrElseW(() => "0")(amount))}
        />
      </FormControl>
    </>
  );
};
