import { Flex, Heading, Stack } from "@chakra-ui/react";
import { Blockchain } from "./blockchain";

export const ControlCenter = () => {
  return (
    <Flex minH={"100vh"} justify={"center"} bg={"gray.800"}>
      <Stack spacing={8} mx={"auto"} maxW={"5xl"} py={12} px={6}>
        <Stack align={"center"} spacing={10}>
          <Heading fontSize={"4xl"}>MUNDUM Control Center</Heading>
          <Stack align={"center"}>
            <Heading fontSize="2xl">Blockchain</Heading>
            <Blockchain />
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
