import { Stack } from "@chakra-ui/react";
import { useStore } from "effector-react";
import * as React from "react";
import { AmountInput } from "../widgets/amount";
import { Buttons } from "../widgets/buttons";
import { RecipientInput } from "../widgets/recipient";
import { blockchainStore } from "./blockchain-store";

export const Transfer = () => {
  const amount = useStore(blockchainStore.$amount);
  const recipient = useStore(blockchainStore.$recipient);
  const locked = useStore(blockchainStore.$locked);
  return (
    <>
      <Stack spacing={4}>
        <RecipientInput
          disabled={locked}
          recipient={recipient}
          onChange={blockchainStore.onRecipientChange}
        />
        <Stack spacing={10}>
          <AmountInput
            disabled={locked}
            amount={amount}
            onChange={blockchainStore.onAmountChange}
          />
          <Buttons.Primary onClick={() => blockchainStore.onTransfer()}>
            Transfer
          </Buttons.Primary>
        </Stack>
      </Stack>
    </>
  );
};
