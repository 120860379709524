import domain from "../domain";
import { Attachment, AttachmentFile } from "../types";

const attachmentUploadMadeProgressEvent = domain.createEvent<{
  attachmentId: string;
  progressPercent: number;
}>("attachmentUploadMadeProgress");

const attachmentAddedEvent = domain.createEvent<AttachmentFile>(
  "attachmentAdded"
);

const attachmentUploadRetriedEvent = domain.createEvent<AttachmentFile>(
  "attachmentUploadRetried"
);

const attachmentUploadRemovedEvent = domain.createEvent<AttachmentFile>(
  "attachmentUploadRemoved"
);

const attachmentCreatedEvent = domain.createEvent<Attachment>(
  "attachmentCreated"
);

const attachmentRemovalRequestedEvent = domain.createEvent<Attachment>(
  "attachmentRemovalRequested"
);

export const attachmentEvents = {
  attachmentRemovalRequestedEvent,
  attachmentUploadRemovedEvent,
  attachmentUploadRetriedEvent,
  attachmentUploadMadeProgressEvent,
  attachmentAddedEvent,
  attachmentCreatedEvent,
};
