import { parse } from "./parse";

export type ValueFilter<T> = (value?: T | string | null) => boolean;

function zeroOrPositiveInteger(
  num?: string | null,
  opts?: { maxDigits?: number }
): boolean {
  return (
    !num ||
    (/^[0-9]*$/.test(num) && (!opts?.maxDigits || num.length <= opts.maxDigits))
  );
}

function decimal(num?: string | null): boolean {
  const parsed = parse.decimal(num);
  return !num || !!parsed;
}

function bigDecimal(num?: string | null): boolean {
  const parsed = [num]
    .map((e) => (!e ? "" : `${e}`))
    .map((e) => e.replace(",", "."))
    .map((e) => e.trim())
    .filter((e) => !e || /^-?(\d+\.?)?\d*$/.test(e))[0];
  return !num || !!parsed;
}

export const filter = {
  decimal,
  bigDecimal,
  zeroOrPositiveInteger,
};
