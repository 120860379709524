import { useStore } from "effector-react";
import { Box, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import { option as O } from "fp-ts";
import DatePicker from "../date-picker";
import { AmountInput } from "../widgets/amount";
import { Buttons } from "../widgets/buttons";
import { RecipientInput } from "../widgets/recipient";
import { blockchainStore } from "./blockchain-store";
import { DurationInput } from "../widgets/duration";
import { BonusInput } from "../widgets/bonus";

export const VestedTransfer = () => {
  const amount = useStore(blockchainStore.$amount);
  const duration = useStore(blockchainStore.$duration);
  const startDate = useStore(blockchainStore.$startDate);
  const year = useStore(blockchainStore.$year);
  const month = useStore(blockchainStore.$month);
  const day = useStore(blockchainStore.$day);
  const bonus = useStore(blockchainStore.$bonus);
  const bonusPercent = useStore(blockchainStore.$bonusPercent);
  const recipient = useStore(blockchainStore.$recipient);
  const locked = useStore(blockchainStore.$locked);
  return (
    <>
      <Stack spacing={4}>
        <RecipientInput
          disabled={locked}
          recipient={recipient}
          onChange={blockchainStore.onRecipientChange}
        />

        <Stack spacing={10}>
          <Stack spacing={2}>
            <Stack
              direction={{ base: "column", sm: "row" }}
              align={"start"}
              justify={"space-between"}
            >
              <Box>
                <FormControl id="vesting-start">
                  <FormLabel>Vesting Start Date</FormLabel>
                  <DatePicker
                    disabled={locked}
                    onChange={(date: any) =>
                      blockchainStore.onStartDateChange(date)
                    }
                    selectedDate={O.getOrElse(() => new Date())(startDate)}
                  />
                </FormControl>
              </Box>
              <AmountInput
                disabled={locked}
                amount={amount}
                onChange={blockchainStore.onAmountChange}
              />
            </Stack>
            <DurationInput
              startDate={startDate}
              disabled={locked}
              duration={duration}
              year={year}
              month={month}
              day={day}
              onChange={blockchainStore.onDurationChange}
              onYearChange={blockchainStore.onYearChange}
              onMonthChange={blockchainStore.onMonthChange}
              onDayChange={blockchainStore.onDayChange}
            />
            <BonusInput
              disabled={locked}
              percent={bonusPercent}
              amount={bonus}
              onChange={blockchainStore.onBonusChange}
              onPercentChange={blockchainStore.onBonusPercentChange}
            />
          </Stack>
          <Buttons.Primary onClick={() => blockchainStore.onTransferVested()}>
            Transfer Vested
          </Buttons.Primary>
        </Stack>
      </Stack>
    </>
  );
};
