import domain from "./domain";
import { backendHandlers } from "./handlers/backend-handlers";
import { contractHandlers } from "./handlers/contract-handlers";

const connectToContractFx = domain.createEffect({
  name: "connectToContractFx",
  handler: contractHandlers.connect,
});

const transferVestedFx = domain.createEffect({
  name: "transferVestedFx",
  handler: contractHandlers.transferVested,
});

const transferFx = domain.createEffect({
  name: "transferFx",
  handler: contractHandlers.transfer,
});

const transferOwnershipFx = domain.createEffect({
  name: "transferOwnershipFx",
  handler: contractHandlers.transferOwnership,
});

const fetchBalanceFx = domain.createEffect({
  name: "fetchBalanceFx",
  handler: contractHandlers.balanceOf,
});

const fetchOpenOrdersFx = domain.createEffect({
  name: "fetchOpenOrders",
  handler: backendHandlers.fetchOpenOrders,
});

const fetchPendingOrdersFx = domain.createEffect({
  name: "fetchPendingOrders",
  handler: backendHandlers.fetchPendingOrders,
});

const fetchConfirmedOrdersFx = domain.createEffect({
  name: "fetchConfirmedOrders",
  handler: backendHandlers.fetchConfirmedOrders,
});

const loginFx = domain.createEffect({
  name: "login",
  handler: backendHandlers.login,
});

const setTxidFx = domain.createEffect({
  name: "setTxid",
  handler: backendHandlers.setTxid,
});

const deleteOrderFx = domain.createEffect({
  name: "deleteOrder",
  handler: backendHandlers.deleteOrder,
});

const cancelOrderFx = domain.createEffect({
  name: "cancelOrder",
  handler: backendHandlers.cancelOrder,
});

const uploadAttachmentFx = domain.createEffect({
  name: "uploadAttachment",
  handler: backendHandlers.uploadAttachment,
});

export const effects = {
  blockchain: {
    connectToContractFx,
    fetchBalanceFx,
    transferVestedFx,
    transferFx,
    transferOwnershipFx,
  },
  backend: {
    fetchOpenOrdersFx,
    fetchPendingOrdersFx,
    fetchConfirmedOrdersFx,
    uploadAttachmentFx,
    setTxidFx,
    deleteOrderFx,
    cancelOrderFx,
    loginFx,
  },
};
