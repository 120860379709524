import axios from "axios";
import { option as O } from "fp-ts/";
import { DeleteOrderPayload, LoginRequest } from "../events";
import { parse } from "../parse";
import { AttachmentUploadRequest } from "../types";

function authConfig() {
  const tokenValue = window.sessionStorage.getItem("token");
  const tokenOption = tokenValue
    ? (JSON.parse(tokenValue!) as O.Some<string>)
    : O.none;
  const token = O.getOrElseW(() => "")(tokenOption);

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const BASE_URL = "/api";

async function fetchOpenOrders({
  chainId,
  contractAddress,
}: {
  chainId: string;
  contractAddress: string;
}) {
  return axios({
    method: "get",
    url: `${BASE_URL}/chain/${chainId}/transfers/open/${contractAddress}`,
    ...authConfig(),
    transformResponse: [(data) => JSON.parse(data, parse.isoDateReviver)],
  }).then((res) => res.data);
}

async function fetchPendingOrders({
  chainId,
  contractAddress,
}: {
  chainId: string;
  contractAddress: string;
}) {
  return axios({
    method: "get",
    url: `${BASE_URL}/chain/${chainId}/transfers/pending/${contractAddress}`,
    ...authConfig(),
    transformResponse: [(data) => JSON.parse(data, parse.isoDateReviver)],
  }).then((res) => res.data);
}

async function fetchConfirmedOrders({
  chainId,
  contractAddress,
}: {
  chainId: string;
  contractAddress: string;
}) {
  return axios({
    method: "get",
    url: `${BASE_URL}/chain/${chainId}/transfers/confirmed/${contractAddress}`,
    ...authConfig(),
    transformResponse: [(data) => JSON.parse(data, parse.isoDateReviver)],
  }).then((res) => res.data);
}

async function setTxid({ orderId, txid }: { orderId: string; txid: string }) {
  return axios({
    method: "put",
    url: `${BASE_URL}/transfers/${orderId}/txid/${txid}`,
    ...authConfig(),
  }).then((res) => res.data);
}

async function deleteOrder({ _id }: DeleteOrderPayload) {
  return axios({
    method: "post",
    url: `${BASE_URL}/transfers/${_id}/deletions`,
    ...authConfig(),
  }).then((res) => res.data);
}

async function cancelOrder({ _id }: DeleteOrderPayload) {
  return axios({
    method: "post",
    url: `${BASE_URL}/transfers/${_id}/cancellations`,
    ...authConfig(),
  }).then((res) => res.data);
}

async function login(loginRequest: LoginRequest) {
  return axios.post(`${BASE_URL}/auth`, loginRequest).then((res) => res.data);
}

async function uploadAttachment({
  upload,
}: {
  upload: AttachmentUploadRequest;
}) {
  if (upload.data) {
    const formData = new FormData();
    formData.append("attachment", JSON.stringify(upload.attachment));
    formData.append("data", upload.data);
    return axios({
      method: "post",
      url: `${BASE_URL}/transfers/upload`,
      data: formData,
      ...authConfig(),
      onUploadProgress: (progressEvent) => {
        upload.onProgress(
          Math.min(
            99,
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        );
      },
    }).then((res) => ({
      data: upload.data,
      attachment: { ...upload.attachment, storageTime: res.data.storageTime },
    }));
  } else {
    return {
      data: upload.data,
      attachment: upload.attachment,
    };
  }
}

export const backendHandlers = {
  fetchOpenOrders,
  fetchPendingOrders,
  fetchConfirmedOrders,
  uploadAttachment,
  setTxid,
  deleteOrder,
  cancelOrder,
  login,
};
