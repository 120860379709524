import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Tag,
} from "@chakra-ui/react";
import { option as O } from "fp-ts";
import { Option } from "fp-ts/lib/Option";
import * as React from "react";
import { frmt } from "../format";

export const DurationInput: React.FC<{
  duration: Option<string>;
  startDate: Option<Date>;
  year: string;
  month: string;
  day: string;
  disabled?: boolean;
  onChange: (amount: string) => void;
  onYearChange: (year: string) => void;
  onMonthChange: (month: string) => void;
  onDayChange: (day: string) => void;
}> = ({
  duration,
  startDate,
  onYearChange,
  onMonthChange,
  onDayChange,
  disabled = false,
  onChange,
}) => {
  return (
    <>
      <FormControl id="duration">
        <Flex align="center" mb={2}>
          <FormLabel>Duration (sec.)</FormLabel>
          <Flex mr={5} align="center">
            <NumberInput
              w="5em"
              min={0}
              size="sm"
              onChange={onYearChange}
              mr={1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            Years
          </Flex>
          <Flex mr={5} align="center">
            <NumberInput
              w="5em"
              min={0}
              size="sm"
              onChange={onMonthChange}
              mr={1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            Months
          </Flex>
          <Flex mr={5} align="center">
            <NumberInput
              w="5em"
              min={0}
              size="sm"
              onChange={onDayChange}
              mr={1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            Days
          </Flex>
        </Flex>
        <Input
          disabled={disabled}
          onChange={(e) => onChange(e.currentTarget.value)}
          value={O.getOrElseW(() => "")(duration)}
        />
        <Input disabled value={frmt.hex(O.getOrElseW(() => "0")(duration))} />
        <Input
          disabled
          value={frmt.date(
            new Date(
              O.getOrElseW(() => new Date())(startDate).getTime() +
                Number(O.getOrElseW(() => "0")(duration)) * 1000
            )
          )}
        />
      </FormControl>
    </>
  );
};
