import { FormControl, FormLabel, Input, Stack } from "@chakra-ui/react";
import { useStore } from "effector-react";
import { option as O } from "fp-ts";
import * as React from "react";
import { Buttons } from "../widgets/buttons";
import { blockchainStore } from "./blockchain-store";

export const Ownership = () => {
  const owner = useStore(blockchainStore.$owner);
  return (
    <>
      <Stack spacing={4}>
        <FormControl id="owner">
          <FormLabel>New Owner</FormLabel>
          <Input
            value={O.getOrElseW(() => undefined)(owner)}
            onChange={(e) =>
              blockchainStore.onOwnerChange(e.currentTarget.value)
            }
          />
        </FormControl>
        <Buttons.Primary onClick={() => blockchainStore.onTransferOwnership()}>
          Transfer Ownership
        </Buttons.Primary>
      </Stack>
    </>
  );
};
