import { parseISO } from "date-fns";

function isoDateParser(date: string): Date {
  return parseISO(date);
}

export type ValueParser<T> = (value?: T | string | null) => T;

function integerParser(value?: string | null): number | null {
  return decimalParser(value, { integer: true });
}

function decimalParser(
  value?: string | null,
  options?: { integer?: boolean }
): number | null {
  const parsed = [value]
    .map((e) => (!e ? "" : `${e}`))
    .map((e) => e.replace(",", "."))
    .map((e) => e.replace(/\.(?=.*?\.)/, ""))
    .map((e) => e.trim())
    .filter(
      (e) => !e || (options?.integer ? /^-?\d+$/ : /^-?(\d+\.?)?\d*$/).test(e)
    )
    .map((e) => Number(e))[0];
  const parsedOrNull = parsed === undefined ? null : parsed;
  // console.log("*** parse", value, parsedOrNull);
  return parsedOrNull;
}

const jsonDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

function isoDateReviver(key: any, value: any) {
  if (typeof value === "string" && jsonDateFormat.test(value)) {
    return parseISO(value);
  }

  return value;
}

export const parse = {
  decimal: decimalParser,
  integer: integerParser,
  isoDate: isoDateParser,
  isoDateReviver,
};
