import { parseISO } from "date-fns";
import dformat from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import { BigNumberish, ethers } from "ethers";

function units(bn: BigNumberish) {
  return ethers.utils.formatUnits(bn);
}

function nativeNum(num: string) {
  return num ? num.replace(/,/g, "") : num;
}

function bigNumber(num?: string) {
  return num ? ethers.utils.parseUnits(num).toString() : "";
}

function bigNumberHex(num?: string) {
  return num ? ethers.utils.parseUnits(num).toHexString() : "";
}

function date(d?: Date | null): string {
  return d ? dformat(d instanceof Date ? d : parseISO(d), "dd.MM.yyyy") : "";
}

function timestamp(d?: Date | null): string {
  return d
    ? dformat(d instanceof Date ? d : parseISO(d), "dd.MM.yyyy hh:mm:ss")
    : "";
}

function hex(duration?: string | null): string {
  return duration ? `0x${Number(duration).toString(16)}` : "";
}

function age(d?: Date | null): string {
  return d
    ? formatDistance(d instanceof Date ? d : parseISO(d), new Date())
    : "";
}

export const frmt = {
  units,
  bigNumber,
  bigNumberHex,
  date,
  hex,
  timestamp,
  age,
  nativeNum,
  explorer(chainId: number, txid?: string): string {
    return chainId === 137
      ? `https://polygonscan.com/tx/${txid}`
      : chainId === 80001
      ? `https://mumbai.polygonscan.com/tx/${txid}`
      : `https://${chainId === 3 ? "ropsten." : ""}etherscan.io/tx/${txid}`;
  },
  explorerAddr(chainId: number, addr?: string): string {
    return chainId === 137
      ? `https://polygonscan.com/address/${addr}#tokentxns`
      : chainId === 80001
      ? `https://mumbai.polygonscan.com/address/${addr}#tokentxns`
      : `https://${
          chainId === 3 ? "ropsten." : ""
        }etherscan.io/address/${addr}#tokentxns`;
  },
};
