import { persist } from "effector-storage/session";
import { BigNumberish, Contract, Signer } from "ethers";
import { option as O } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { Option } from "fp-ts/lib/Option";
import jwt_decode from "jwt-decode";
import domain from "./domain";
import { AuthToken, events } from "./events";
import { Order } from "./types";

const $balance = domain
  .createStore<Option<BigNumberish>>(O.none, {
    name: "balance",
  })
  .on(events.balanceRetrievedEvent, (_, payload) => O.fromNullable(payload));

const $signer = domain
  .createStore<Option<Signer>>(O.none, {
    name: "signer",
  })
  .on(events.signerRetrievedEvent, (_, payload) => O.fromNullable(payload));

const $contract = domain
  .createStore<Option<Contract>>(O.none, {
    name: "contract",
  })
  .on(events.contractConnectedEvent, (_, payload) =>
    pipe(
      O.fromNullable(payload),
      O.map((payload) => payload.contract)
    )
  );

const $chainId = domain
  .createStore<Option<number>>(O.none, {
    name: "chainId",
  })
  .on(events.contractConnectedEvent, (_, payload) =>
    pipe(
      O.fromNullable(payload),
      O.map((payload) => payload.chainId)
    )
  );

const $orders = domain
  .createStore<Order[]>([], {
    name: "orders",
  })
  .on(events.ordersReceivedEvent, (_, payload) => payload)
  .on(events.orderDeletedEvent, (state, payload) =>
    state.filter((order) => order._id !== payload._id)
  );

const $ordersPending = domain
  .createStore<Order[]>([], {
    name: "orders",
  })
  .on(events.pendingOrdersReceivedEvent, (_, payload) => payload)
  .on(events.orderCancelledEvent, (state, payload) =>
    state.filter((order) => order._id !== payload._id)
  );

const $ordersConfirmed = domain
  .createStore<Order[]>([], {
    name: "orders",
  })
  .on(events.confirmedOrdersReceivedEvent, (_, payload) => payload);

const $orderSelected = domain
  .createStore<Option<Order>>(O.none, {
    name: "orderSelected",
  })
  .on(events.orderSelectedEvent, (_, payload) => payload);

const $token = domain
  .createStore<Option<string>>(O.none, {
    name: "token",
  })
  .on(events.authTokenReceivedEvent, (_, payload) =>
    O.fromNullable(payload.token)
  )
  .reset(events.logoutEvent);

const $permissions = $token.map((token) =>
  pipe(
    token,
    O.map((token) => jwt_decode<AuthToken>(token).permissions),
    O.getOrElseW(() => [])
  )
);

const $forceReset = $permissions.map((permissions) =>
  permissions.some((p) => p === "user:passwordreset")
);

persist({ store: $token });

export const authModel = {
  $token,
  $forceReset,
  $permissions,
};

export const stores = {
  $token,
  $permissions,
  $balance,
  $signer,
  $contract,
  $chainId,
  $orders,
  $ordersPending,
  $ordersConfirmed,
  $orderSelected,
};
