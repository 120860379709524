import { BigNumber, Contract, ethers } from "ethers";
import { OwnershipTransfer } from "../events";

declare global {
  interface Window {
    ethereum: any;
  }
}

const mundumVestingAbi = [
  "function transferOwnership(address newOwner)",
  "function addClaim(address benefciary, uint amount, uint start, uint duration, uint bonusAmount)",
  // "event Transfer(address indexed from, address indexed to, uint amount)",
];

type ChainId = number;

const contractAddress: Record<ChainId, string> = {
  31337: "0x1AF97622723fEd5621fc764d0871998d9f2BAE14",
  //   3: "0xa1420a8b662cCF644a426adA5aDE4C4D41C2A4b1",
  // 3: "0x5be3Eac96B404406256e5411afE0D0ecC282D57d",
  // 3: "0xB6015cBa726f28720BB76e0F99565c1E7F40C31e",
  //3: "0x8c8Ce13836f2B930e07594e4985303c986e08D26",
  //1: "0xCDb9D30a3BA48CdfCB0EcbE19317E6cf783672f1",
  137: "0x93AbBFFBC5172FDf30dA6c928F9a33622811BA13",
  80001: "0xDbC1603b5c634a36380D0283EdF95a6Fa170800F",
};

async function connect() {
  if (typeof window.ethereum !== undefined) {
    await window.ethereum.enable();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const chainId = (await provider.getNetwork()).chainId;
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      contractAddress[chainId],
      mundumVestingAbi,
      provider.getSigner()
    );
    contract.connect(signer);
    return { chainId, contract };
  } else {
    throw new Error("No injected web3 found");
  }
}

async function balanceOf(contract: Contract) {
  return await contract.balanceOf(contract.signer.getAddress());
}

async function transferVested(params: {
  contract: Contract;
  recipient: string;
  amountUnits: BigNumber;
  bonusUnits: BigNumber;
  startDateUnix: number;
  duration: number;
}) {
  const {
    contract,
    recipient,
    amountUnits,
    startDateUnix,
    bonusUnits,
    duration,
  } = params;
  return await contract.addClaim(
    recipient,
    amountUnits,
    startDateUnix,
    duration,
    bonusUnits
  );
}
async function transfer(params: {
  contract: Contract;
  recipient: string;
  amountUnits: BigNumber;
}) {
  const { contract, recipient, amountUnits } = params;
  return await contract.transfer(recipient, amountUnits);
}

async function transferOwnership(params: OwnershipTransfer) {
  const { contract, newOwner } = params;
  return await contract.transferOwnership(newOwner);
}

export const contractHandlers = {
  connect,
  balanceOf,
  transfer,
  transferVested,
  transferOwnership,
};
